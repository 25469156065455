import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
// import Placeholder from "../components/Placeholder";
import News from "../components/News2";
import FounderSection from "../components/FounderSection";
import MissionSection from "../components/MissionSection";
import HomePopup from "../components/HomePopup";
import SEO from "../components/seo";
import NewsTicker from "../components/Newsticker";
import AccoladesSection from "../components/AccoladesSection";

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Chinmaya Vidyalaya Vasant Vihar | Chinmaya Mission | Schools in Delhi"
        description="Chinmaya Vidyalaya Vasant Vihar is among the best CBSE schools in Delhi under the aegis of the Chinmaya Mission. Ranked 1st in Challengers Category"
        keywords="Chinmaya Mission, schools in delhi, cbse schools in delhi,best schools in delhi,Chinmaya Vidyalaya Vasant Vihar,chinmaya vidyalaya,chinmaya mission school,chinmaya mission delhi"
        img="https://www.chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
      />
      <NewsTicker />
      <HomePopup />
      <Hero />
      <AccoladesSection />
      <News />
      <FounderSection />
      <MissionSection />
    </Layout>
  );
};

export default IndexPage;
