import React from "react";
import { Link } from "gatsby";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";


export default function Hero() {

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="hero">
      <div className="hero__slider desktop">
        <Slider {...settings}>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-1.jpg"></img>
          </div>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-2.jpg"></img>
          </div>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-3.jpg"></img>
          </div>
        </Slider>
      </div>
      <div className="hero__slider mobile">
        <Slider {...settings}>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-mobile-1.jpg"></img>
          </div>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-mobile-2.jpg"></img>
          </div>
          <div className="hero__slider__slide">
            <img className="hero__img" src="https://chinmayavvdelhi.ac.in/assets/images/hero-mobile-3.jpg"></img>
          </div>
        </Slider>
      </div>
      <div className="hero__content">
        <div className="hero__quote">
          <div className="hero__quote__text">
            "Children are not <br />
            vessels to be filled <br />
            but lamps to be lit"
          </div>
          <div className="hero__quote__author">
            Swami Chinmayananda Saraswati
          </div>

          <div className="hero__quote__buttons">
            <Link to="/registrations/" className="btn-white">
              Registrations
            </Link>
            <Link to="/circulars" className="btn-white">
              School Circulars
            </Link>
            {/* <Link to="/calendar/" className="btn-white">
              School Calendar
            </Link> */}
            <Link to="/hall-of-fame/" className="btn-white">
              Hall of Fame
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
