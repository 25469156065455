import React from 'react';
import "./styles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import trophyimg from "../../images/school-awards/award-02.png";
import awardImg1 from "../../images/school-awards/award-04.png";
import awardImg2 from "../../images/school-awards/award-05.png";
import awardImg3 from "../../images/school-awards/award-06.png";
import awardImg4 from "../../images/school-awards/award-07.png";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    responsive: [
        {
            breakpoint: 1340,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 920,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },

    ]

};

const AccoladesSection = () => {
    return (
        <div className="awards_new_section">
            <div className='awards_new_section__content'>
                {/* <h2 className="awards_new_section__heading2">Accolades Won By the School</h2> */}
                <p>‘All our dreams can come true if we have the courage to pursue them.’
                    Chinmaya Vidyalaya, New Delhi, in its pursuit of excellence and commitment to provide
                    quality education to the 21st century learners, has been appreciated and applauded for its
                    concerted efforts at various platforms.</p>

                <h2 className="awards_new_section__heading2">A glimpse of the accolades won by the school</h2>

                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <div className="card">
                            <img src={trophyimg}></img>
                            <p>Chinmaya Vidyalaya ranked No.1 for “Holistic Education” in New Delhi, in the CBSE Category for The North India’s School Merit Awards. Survey Conducted by Education Today.</p>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                        <div className="card">
                            <img src={trophyimg}></img>
                            <p>Chinmaya Vidyalaya, New Delhi ranked 7th in ‘Leaders Category’, in South - West Delhi Zone, in ‘Times Survey 2022’. Awarded By: Times of India</p>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                        <div className="card">
                            <img src={trophyimg}></img>
                            <p>Of the 3000 rated schools by Careers360, only 50 best schools in India got the highest ratings of 5A. The next rating AAAA+ is earned by nearly 250 top schools in India and Chinmaya Vidyalaya, New Delhi was one of the AAAA+ .</p>
                        </div>
                    </div>
                    <div className="col col-12 col-lg-6">
                        <div className="card">
                            <img src={trophyimg}></img>
                            <p>Chinmaya Vidyalaya received National Level Recognition for being “Top
                                50 Futuristic School of India" in 2022. Awarded By: Edu-Mirror the Educational Magazine Powered by Esteem International Ranking Pvt. Ltd</p>
                        </div>
                    </div>
                </div>

                <div className="images">
                    <Slider {...settings} >
                        <div>
                            <img className="award_image" src={awardImg1}></img>
                        </div>
                        <div>
                            <img className="award_image" src={awardImg2}></img>
                        </div>
                        <div>
                            <img className="award_image" src={awardImg3}></img>
                        </div>
                        <div>
                            <img className="award_image" src={awardImg4}></img>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default AccoladesSection;
