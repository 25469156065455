import React, { useState } from "react";
import "./style.scss";
import data from "../../data/calendar";
import { Link } from "gatsby";
import YoutubeOverlay from "../YoutubeOverlay";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function News() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showYoutube, setShowYoutube] = useState(false);

  const settings = {
    // dots: true,
    infinite: true,
    arrows: true,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function toggleYoutube() {
    setShowYoutube(!showYoutube);
  }
  return (
    <div className="news">
      <YoutubeOverlay
        show={showYoutube}
        onClickClose={toggleYoutube}
        src={data[currentIndex].videoSrc}
      />
      <h2 className="heading news__heading">Latest News</h2>
      <div className="news__content">
        <div className="news__header">
          <div className="news__header__card hide-scrollbar">
            {data.slice(0, 5).map((item, i) => (
              <div
                className={`news__header__card__date ${
                  i === currentIndex && "news__header__card__date--selected"
                }`}
                role="button"
                tabIndex="-1"
                onClick={() => setCurrentIndex(i)}
              >
                <div className="news__header__card__date__d">{item.d}</div>
                <div className="news__header__card__date__m">{item.m}</div>
                <div className="news__header__card__date__y">{item.y}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="news__body">
          <div className="row news__body__row">
            <div className="col col-12 col-md-4">
              <div className="news__body__left">
                <h2 className="news__body__left__title">
                  {data[currentIndex].title}
                </h2>

                <div className="news__body__left__date">
                  {data[currentIndex].fullDate}
                </div>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      data[currentIndex].text.length > 700
                        ? data[currentIndex].text.slice(0, 700) + "....."
                        : data[currentIndex].text,
                  }}
                ></p>

                {/* <Link to="/" className="btn-dark">
                  Read More
                </Link> */}

                <div className="s_icons">
                  <div className="s_box">
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${data[currentIndex].videoSrc}`}
                      target="_blank"
                      className=""
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="s_box">
                    <a
                      href={`https://twitter.com/intent/tweet?url=${data[currentIndex].videoSrc}`}
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>
                  </div>
                  <div className="s_box">
                    <a
                      href={`https://www.linkedin.com/sharing/share-offsite/?url=${data[currentIndex].videoSrc}`}
                      target="_blank"
                      className=""
                    >
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-8">
              <div className="news__body__right">
                {!data[currentIndex].isVideo ? (
                  <div className="slidercontainer">
                    <Slider {...settings}>
                      {data[currentIndex].images.map((item, i) => (
                        <div className="news__body__right__img">
                          <img
                            key={`herodesk-${i}`}
                            alt="Hero Image"
                            loading="eager"
                            src={item}
                          ></img>
                        </div>
                      ))}
                    </Slider>
                  </div>
                ) : (
                  <div className="news__body__right__video">
                    <div className="news__body__right__video__overlay">
                      <div
                        onClick={toggleYoutube}
                        role="button"
                        tabIndex="-2"
                        className="news__body__right__video__playbutton"
                      >
                        <i class="fa fa-play" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div className="news__body__right__img">
                      <img alt="" src={data[currentIndex].images[0]}></img>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
