import React from "react";
import "./style.scss";
import { Link } from "gatsby";

import icon1 from "../../images/assets/news/icons/icons-01-01.png";
import icon2 from "../../images/assets/news/icons/icons-01-02.png";
import icon4 from "../../images/assets/news/icons/icons-01-03.png";
import icon3 from "../../images/assets/news/icons/icons.png";

export default function MissionSection() {
  return (
    <section className="missionsection">
      <h2 className="heading">Chinmaya Vidyalaya Vision</h2>
      <p>
        The inner transformation of individuals through value-based education,
        enabling them to be successful and positive contributors to society,
        resulting in a prosperous and happy world around them.
      </p>
      <br />
      <br />

      <h2 className="heading">Chinmaya Vidyalaya Mission</h2>
      <center>
        <p style={{ fontSize: "20px" }}>
          कटिबध्दा वयं सर्वे लक्ष्यं साधयितुं वरम्।
          <br />
          शिक्षायास्त्रिविधं यद्वै ज्ञानं सेवा च कौशलम्॥
        </p>
      </center>

      <center>
        <p style={{ fontSize: "20px" }}>
          kaṭibaddhā vayaṁ sarve lakṣyaṁ sādhayituṁ varam |
          <br />
          śikṣāyāstrividhaṁ yadvai jñānaṁ sevā ca kauśalam ||
        </p>
      </center>
      <center>
        <em>
          We are determined to achieve the threefold noble goals of education,
          namely, vision, spirit of service and efficiency.
        </em>
      </center>

      <br />
      <br />

      <h2 className="heading">Chinmaya Vision Programme</h2>
      <p>
        Chinmaya Vidyalayas are ‘schools with a difference’ due to the Chinmaya
        Vision Programme (CVP); a comprehensive educational programme which
        integrates the best in our culture and philosophy in education, with the
        child as the focal point.
        <br />
        <br />
        CVP is a vision inducing programme, aimed at giving students a true
        vision of life to help them face challenges in a positive and dynamic
        manner, and heartily contribute to society. CVP is not only value
        education, but also value based education, ensuring academic excellence
        is complemented by character moulding and personality development of
        students.
        <br />
        <br />
        The Chinmaya Vision Programme can be identified under the following four
        heads.
      </p>
      <br />
      <br />

      <div className="row">
        <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="missionsection__icon">
            <img src={icon1}></img>
            <div>Integrated Development</div>
          </div>
        </div>
        <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="missionsection__icon">
            <img src={icon2}></img>
            <div>Indian Culture</div>
          </div>
        </div>
        <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="missionsection__icon">
            <img src={icon3}></img>
            <div>Universal Outlook</div>
          </div>
        </div>
        <div className="col col-12 col-md-6 col-lg-4 col-xl-3">
          <div className="missionsection__icon">
            <img src={icon4}></img>
            <div>Patriotism</div>
          </div>
        </div>
      </div>

      <div className="founder__buttons">
        <Link to="/academics/" className="btn-dark">
          Academics
        </Link>
        <Link to="/infrastructure/" className="btn-dark">
          Infrastructure
        </Link>
        <Link to="/chinmaya-vision-programme/" className="btn-dark">
          More About CVP
        </Link>
      </div>
      <br />
      <p>
        The Central Board of Secondary Education (CBSE) has featured the
        Educational Philosophy of Chinmaya Vidyalayas as one of the top value
        based educational programmes of the country.
      </p>
    </section>
  );
}
